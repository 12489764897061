import React, {useState, useEffect} from 'react';
// import './Mission.css';
import {Navigation} from '../../components/navigation/Navigation';
// import bg from '../../assets/mission-bg.png';
import {getPage} from '../../api';

export const Mission = ({isPage}) => {

  const [missionContent, setMissionContent] = useState(null)

  const getMissionPageContent = () => {
    getPage(12).then(result => {
      setMissionContent(result.content.rendered);
    })
  }

  useEffect(() => {
    getMissionPageContent()
  }, [])

  return (
    <>
      { isPage && <Navigation /> }
      <div className={`${isPage ? 'mt-32' : ''} mission-content-container px-8 max-w-screen-lg mx-auto`}>
      <h2 className={'text-white font-primary text-center w-full text-4xl py-4 mt-8'}>black ether</h2>
      <p className={'text-white font-secondary px-4 py-4 mb-8 mt-2 max-h-[320px] overflow-y-scroll border-y border-white/50 leading-8 mission-post-container'}>{/*<img className={'mission-bg'} src={missionBackground}/>*/}<span dangerouslySetInnerHTML={{__html: missionContent}}></span></p>
      <a href="https://docs.google.com/forms/d/1VGRkTRbUVONR4sGKScQlwmw84sZJuAOLH76CgKFQT4s/prefill" target="_blank" ><p className={'mb-4 text-center font-primary uppercase text-white border border-white w-full p-2'}>compilation submission form</p></a>
      {/*<a href="https://www.instagram.com/katalog.ooo" target="_blank"  ><p className={' mb-4 text-center font-primary uppercase text-white border border-white w-full p-2'}>contract download</p></a>*/}
      <p className={'italic font-primary text-white ml-4 mt-8'}>Contact</p>
      <p className={'italic font-primary text-white text-sm ml-4'}>warrenjones@blackether.life</p>
      <p className={'italic font-primary text-white text-sm ml-4 mb-16'}>yaradulac@blackether.life</p>
      </div>
    </>
    )
}

export default Mission;
