import React, {useState, useEffect, useContext} from 'react';
import './PostPagination.css';

export const PostPagination = ({total, active, handleActivePageChange, floating}) => {
  const [pageArr, setPageArr] = useState([])
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    let arr = []
    for (var i = 1; i <= total; i++) {
      arr.push(i)
    }
    setPageArr(arr)
  }, [total])

  const handleActivePage = (pg) => {
    setActivePage(pg)
    handleActivePageChange(pg)
  }

  return (
      <ul className={`pagination-container ${floating ? 'floating-pagination-container' : ''}`}>
      {pageArr.map((pg, i) => {
        return (
          <li onClick={() => handleActivePage(i + 1)} className={`pagination-item ${activePage == i + 1 ? 'active-pagination-item' : ''}`}>{pg}</li>
        )
      })}
      </ul>
  )
}

export default PostPagination
