import React, {useState, useEffect} from 'react';
import './About.css';
import {Navigation} from '../../components/navigation/Navigation';
import bg from '../../assets/about-bg.png';
import {getPage} from '../../api';

export const About = ({isPage}) => {

  const [aboutContent, setAboutContent] = useState(null)
  const [aboutBackground, setAboutBackground] = useState(null);

  const getAboutPageContent = () => {
    getPage(12).then(result => {
      setAboutContent(result.content.rendered);
      setAboutBackground(result._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url);
    })
  }

  useEffect(() => {
    getAboutPageContent()
  }, [])

  return (
    <>
      { isPage && <Navigation /> }
      <div className={'about-content-container'}>
      <h2 className={'about-title'}>about</h2>
      <p className={'about-copy'}>{/*<img className={'about-bg'} src={aboutBackground}/>*/}<span dangerouslySetInnerHTML={{__html: aboutContent}}></span></p>
      <a href="https://www.instagram.com/katalog.ooo" target="_blank"  className={'about-link'}><p>contact us</p></a>

      </div>
    </>
    )
}

export default About;
