import React from 'react';

const CarouselSliderItem = ({center, title, content, thumb}) => {
    return (
        <div className={`carousel-slider cursor-pointer ${center ? "text-center" : 'text-start'}`}>
            {thumb && (
                <div className="thumb transition duration-400 hover:shadow-xl hover:-translate-y-[4px]">
                    <img src={thumb} alt="Carousel" className={'shadow'}/>
                </div>
            )}
            {/*<div className="content">
                <h5 className={'font-print inline-block text-white bg-strange-green text-2xl mt-4 pt-[2px] px-[1px] pb-0 mb-1'}>{title}</h5>
                <h5 className={'font-print text-black text-2xl pt-0 mt-0'}>{content}</h5>
            </div>*/}
        </div>
    );
};

export default CarouselSliderItem;
