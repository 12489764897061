import React, {useState} from 'react';
import './Releases.css';
import {useNavigate} from 'react-router-dom';
import {Navigation } from '../../components/navigation/Navigation';
import CarouselSlider from '../../components/carousel-slider/CarouselSlider';
import bg from '../../assets/about-bg.png';
import blank from '../../assets/blank-square.png';
import album1 from '../../assets/album1.jpg';
import album2 from '../../assets/album2.jpg';
import album3 from '../../assets/album3.jpg';
import album4 from '../../assets/album4.jpg';
import album5 from '../../assets/album5.jpg';
import {getPosts, getPage} from '../../api';



export const Releases = ({isPage, handlePlayerAudio}) => {
  const navigate = useNavigate();
  const [downloadedReleases, setDownloadedReleases] = useState([])
  const [releasesBackground, setReleasesBackground] = useState(null)

  const getReleasesThumbnail = () => {
    getPage(30).then(result => {
      setReleasesBackground(result._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url);
    })
  }

  const getReleases = (page) => {
    getPosts(page, 3).then(result => {
      console.log(result)
      setDownloadedReleases(result.data.map(post => ({
        id: post.id,
        title: post.title.rendered,
        slug: post.slug,
        audioEmbed: post.acf.audio_embed,
        albumArtist: post.acf.album_artist,
        albumTitle: post.acf.album_title,
        excerpt: post.excerpt.rendered,
        content: post.content.rendered,
        banner: post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
        thumbnail: post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url
      })))
      // setDownloadedPosts([...result.data])
      // setDownloadedPosts([...result.data])
      // setTotalPages(result.headers['x-wp-totalpages'])
    })
  }

  const handlePost = (post) => {
    navigate(`/katalog/${post.slug}`)
  }

  React.useEffect(() => {
    getReleases(1)
    getReleasesThumbnail()
  }, [])

  return (
    <>
    { isPage && <Navigation /> }
    <div className={`bg-black hide-scrollbar ${isPage ? 'single-page-releases-container' : ''}`}>
    <h3 className={'releases-page-title'}>:audio</h3>
    {
      !downloadedReleases.length &&
      <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
    }
    {
    <div className={`release-flex-container hide-scrollbar ${isPage ? 'single-page-container' : ''}`}>
    { downloadedReleases && downloadedReleases.map((post, idx) => {
      return (
        <div onClick={() => handlePlayerAudio(downloadedReleases[idx])} className={'release-outer-container'}>
          <div className={'release-image-container'}>
            <img src={post.banner} style={{width: '100%', height: 'auto'}} />
          </div>
          <h3 className={'release-album-artist'}><span className={'inner-block-background-wrap'}>{post.albumArtist}</span></h3>
          <h3 className={'release-album-title'}>{post.albumTitle}</h3>
        </div>
      )})
    }
      {/*<img className={'releases-bg'} src={releasesBackground}/>*/}
      { downloadedReleases.map((release, idx) => {
          return (
            <div onClick={() => isPage ? handlePlayerAudio(downloadedReleases[idx]) : handlePost(downloadedReleases[idx])} className={'release-container'}>
              <div className={'release-img-container'}>
                <img src={release.banner} />
              </div>
              <h3>{release.albumArtist}<br/>{release.albumTitle}</h3>
            </div>
          )
      })}

    </div>
  }
  </div>
  </>
)
}

export default Releases;
