import React, {useState, useEffect} from 'react';
import { htmlEncode } from '../../../helpers/helpers';
// import {unescape} from 'underscore'
import {format} from 'date-fns'
import './PostList.css';
const BASE_URL= process.env.REACT_APP_API_BASE_URL;

export const PostList = ({posts}) => {
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    const handleScroll = (e) => setScrollTop(window.scrollY)
    window.addEventListener('scroll', handleScroll)
    return () => {window.removeEventListener('scroll', handleScroll)}
  }, [])

  useEffect(() => {
    console.log(window)
  }, [scrollTop])

  return (
    <>
    <div className={'post-list-container'}>
      { posts.length !== 0 &&
        posts.map((post) => {
          return (
            <div className={'post-single-container'}>
              <h1>{htmlEncode(post.title.rendered)} {scrollTop}</h1>
              <h6>{format(new Date(post.date), 'HH:mmaa dd/MM/yyyy')}</h6>
              <div className={'image-placeholder'}>
              { post._embedded['wp:featuredmedia'] !== undefined &&
                <img src={post._embedded['wp:featuredmedia'][0].source_url} />
              }
              </div>
              <p>{htmlEncode(post.excerpt.rendered.replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, ''))}</p>
            </div>
          )
        })
      }

    </div>
    </>
  )
}

export default PostList;
