import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import './PostGrid.css';
import { htmlEncode } from '../../../helpers/helpers';
import {format} from 'date-fns'
import Column from '../../core/column/Column';

export const PostGrid = ({posts, itemsPerRow, compact, header}) => {
  return (
    <>
    <div className={`post-grid-container ${compact ? 'compact-grid-container' : ''}`} >
    <div className={'post-grid-inner-container'}>
    {
      posts.length !== 0 &&
      posts.map((post) => {
        return (
          <Column cx={itemsPerRow}>
            <div className={'post-single-grid-item'}>
            <Link className={'post-single-link'} to={`/single/${post.slug}`}>
              <div className={'grid-image-container'}>
              { post._embedded['wp:featuredmedia'] !== undefined &&
                <img src={post._embedded['wp:featuredmedia'][0]['media_details']['sizes']['thumbnail'].source_url} />
              }
              </div>
              <h3>{htmlEncode(post.title.rendered)}</h3>
              </Link>
            </div>
          </Column>
        )
      })
    }
    </div>
    </div>
    </>
  )
}

export default PostGrid;
