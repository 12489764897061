import React, {useState, useEffect} from 'react';
import './PostEmbedSingle.css';
export const PostEmbedSingle = ({post}) => {
  return (
    <>
      { post &&
      <div className={'post-embed-container'}>
          <p dangerouslySetInnerHTML={{__html: post.audioEmbed}}></p>
      </div>
      }
    </>
  )
}

export default PostEmbedSingle;
