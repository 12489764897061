import React, {useState, useEffect} from 'react';
import './PostSlider.css';

export const PostSlider = ({posts}) => {
  return (
    <>
    <div className={'post-slider-container'}>
    { posts.length !== 0 &&
      posts.map((post) => {
        return (
          <div className={'post-slide-container'}>
            <div className={'post-slide-inner-container'}>
              <h1>{post.title.rendered}</h1>
              <h6>{post.date}</h6>
              <div className={'image-placeholder'}>
                <p>Placeholder</p>
              </div>
              <p>{post.content.rendered}</p>
            </div>
          </div>
        )
      })
    }
      <div className={'post-slide-container'}>
        <div className={'post-slide-inner-container'}>
          <h1>This is my first post title</h1>
          <h6>14th January 2024</h6>
          <div className={'image-placeholder'}>
            <p>Placeholder</p>
          </div>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam quis malesuada sem, in porta tellus. Suspendisse at posuere dui. In convallis dapibus consequat. Cras a lorem quam. Mauris tincidunt enim ac interdum rutrum. Etiam rutrum euismod enim a efficitur. Aliquam convallis non tortor ut ultricies. Cras ac felis id eros lacinia viverra quis id ligula. Aenean bibendum congue ex, vel faucibus lacus lobortis vitae. Praesent ac interdum est, non ullamcorper purus. Aliquam cursus tristique consequat. Ut tincidunt libero vel libero placerat consequat. Aliquam nisi eros, condimentum in felis ut, maximus scelerisque magna.</p>
        </div>
      </div>

      <div className={'post-slide-container'}>
        <div className={'post-slide-inner-container'}>
        <h1>This is my first post title</h1>
        <h6>14th January 2024</h6>
        <div className={'image-placeholder'}>
          <p>Placeholder</p>
        </div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam quis malesuada sem, in porta tellus. Suspendisse at posuere dui. In convallis dapibus consequat. Cras a lorem quam. Mauris tincidunt enim ac interdum rutrum. Etiam rutrum euismod enim a efficitur. Aliquam convallis non tortor ut ultricies. Cras ac felis id eros lacinia viverra quis id ligula. Aenean bibendum congue ex, vel faucibus lacus lobortis vitae. Praesent ac interdum est, non ullamcorper purus. Aliquam cursus tristique consequat. Ut tincidunt libero vel libero placerat consequat. Aliquam nisi eros, condimentum in felis ut, maximus scelerisque magna.</p>
        </div>
      </div>

      <div className={'post-slide-container'}>
      <div className={'post-slide-inner-container'}>
        <h1>This is my first post title</h1>
        <h6>14th January 2024</h6>
        <div className={'image-placeholder'}>
          <p>Placeholder</p>
        </div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam quis malesuada sem, in porta tellus. Suspendisse at posuere dui. In convallis dapibus consequat. Cras a lorem quam. Mauris tincidunt enim ac interdum rutrum. Etiam rutrum euismod enim a efficitur. Aliquam convallis non tortor ut ultricies. Cras ac felis id eros lacinia viverra quis id ligula. Aenean bibendum congue ex, vel faucibus lacus lobortis vitae. Praesent ac interdum est, non ullamcorper purus. Aliquam cursus tristique consequat. Ut tincidunt libero vel libero placerat consequat. Aliquam nisi eros, condimentum in felis ut, maximus scelerisque magna.</p>
      </div>
    </div>
    </div>
    </>
  )
}

export default PostSlider;
