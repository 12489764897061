import React, {useState, useEffect} from 'react';
import Videostream from '../../pages/videostream/Videostream';
import './Player.css';
export const Player = ({audio, streamActive, setAudio, active, setActive}) => {

  const handleHidePlayer = () => {
    setActive(!active)
  }

  const handleExitPlayer = () => {
    setActive(false);
    setAudio(null)
  }

  return (
    <>
      { audio &&
      <div className={`post-player-container ${active ? 'post-player-active' : null}`}>
        {active && <div className={`hide-player-btn`} onClick={handleExitPlayer}>x</div>}
        <div className={`hide-player-btn`} onClick={handleHidePlayer}>{active ? 'hide' : 'd[-_-]b'}</div>
          <p dangerouslySetInnerHTML={{__html: audio.audioEmbed}}></p>
      </div>
      }
      { streamActive &&
        <div className={`post-player-container ${active ? 'post-player-active' : null}`}>
          {active && <div className={`hide-player-btn`} onClick={handleExitPlayer}>x</div>}
          <div className={`hide-player-btn`} onClick={handleHidePlayer}>{active ? 'hide' : 'd[-_-]b'}</div>
          <Videostream />
        </div>
      }
    </>
  )
}

export default Player;
