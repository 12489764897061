export function htmlEncode(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export function sortByDateDesc(a, b) {
  const dateA = new Date(a.monthYear.replace("-", "/"));
  const dateB = new Date(b.monthYear.replace("-", "/"));
  return dateB - dateA;
}
