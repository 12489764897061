import React, {useState, useEffect} from 'react';
import {Link } from 'react-router-dom';
import {getPostById} from '../../api'

export const CarouselInfo = ({slide}) => {
  const [readMoreActive, setReadMoreActive] = useState(null)
  const [featuredRecords, setFeaturedRecords] = useState([])

  useEffect(() => {
    setFeaturedRecords([])
    setReadMoreActive(false)
    if(slide && slide.featured_records) {
      getPostById(slide.featured_records.map(f => f.ID))
        .then(res => setFeaturedRecords(res))
    }

  }, [slide])
  return (
    <>
      <div className={'absolute h-[calc(100%)] hide-scrollbar  flex justify-center items-center right-0 bg-[#000d] text-white border-l border-l-egyptian-blue blur-panel z-[2] w-[100%] md:w-[50%] max-h-[838px] h-full'}>
      { slide &&
        <>
          <div className={'px-8 pb-16 max-w-[520px] overflow-x-hidden hide-scrollbar flex flex-col flex-start max-h-[calc(100%-96px)] overflow-scroll'}>
            <h3 className={'font-primary text-2xl mb-0 text-strange-green border-b border-white'} dangerouslySetInnerHTML={{__html: slide.artist ? slide.artist : slide.title}}></h3>
            {slide.instagram && <h6 className={'mb-4 mt-[3px] text-left md:text-right w-full'}><a className={'fill-white float-right'} href={slide.instagram} target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="1.25em" className={'translate-y-[0.25rem] -translate-x-[0.25rem]'} viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg></a></h6>}
            <h4 className={'font-secondary text-4xl mb-0 text-[#666]'} dangerouslySetInnerHTML={{__html: slide.album}}></h4>
            <div className={'pb-8 mt-4'}>
              <img src={slide.thumb} />
            </div>
            <p className={'font-secondary text-md wp-blog-content'} dangerouslySetInnerHTML={{__html: !readMoreActive ? slide.content.split('</p>').shift() : slide.content}}></p>
            {!readMoreActive && <button onClick={() => setReadMoreActive(true)} className={'font-secondary mt-8 border border-strange-green px-2 pt-1 text-lg font-bold'}><p className={'-translate-y-[2px]'}>Read More +</p></button>}

            <div className={'my-4'} dangerouslySetInnerHTML={{__html: slide.bandcamp_embed}}></div>
            <div className={'grid grid-cols-4 gap-4 mt-12'}>
              {featuredRecords && featuredRecords.map(post => {
                return (
                  <Link to={`/discography#${post.slug}`}>
                  <div className={'col-span-1 cursor-pointer'}>
                    <img src={post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url} />
                    <p className={'text-sm mt-2 font-print font-bold'} dangerouslySetInnerHTML={{__html: post.title.rendered}}></p>
                  </div>
                  </Link>
                )
                })
              }
            </div>
          </div>
        </>
      }
      </div>
    </>
  )
}

export default CarouselInfo
