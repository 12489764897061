import {useState, useEffect} from 'react'
// import placeholder from '../assets/images/43-placeholder.png'
// import DOMPurify from 'dompurify';
import {CarouselSlider, CarouselInfo} from '../../components/index';
import {getPage, getPosts} from '../../api'
import {Navigation} from '../../components/navigation/Navigation';


export const Discography = ({isPage}) =>  {

  // const PAGE_ID = -1;
  const CATEGORY_ID = 5;


  const [releases, setReleases] = useState(null)
  const [savedReleases, setSavedReleases] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeListIndex, setActiveListIndex] = useState(0)
  const [listViewActive, setListViewActive] = useState(true);
  // const [totalPages, setTotalPages] = useState(null)
  // const [currentPage, setCurrentPage] = useState(1)

  const getReleases = (page) => {
    getPosts(page, CATEGORY_ID, null, true).then(result => {
      let data = result.data.map(post => ({
        id: post.id,
        title: post.title.rendered,
        subtitle: post.acf.post_subtitle,
        excerpt: post.content.excerpt,
        content: post.content.rendered,
        thumb: post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
        slug: post.slug,
        album: post.acf.release_album,
        artist: post.acf.release_artist,
        video_embed: post.acf.release_video,
        bandcamp_embed: post.acf.bandcamp_embed
      }))
      if(!savedReleases) {
        setSavedReleases(data)
      }
      const hash = window.location.hash.replace(/^#/, '');
      if(hash) {
        const selectedIndex = data.map(r => r.slug).indexOf(hash)
        let selectedPost = data[selectedIndex];
        data.splice(data.map(r => r.slug).indexOf(hash), 1)
        data.unshift(selectedPost);
      } else {
        setListViewActive(true)
      }
      setReleases(data)
      setActiveIndex(0)
    })
  }

  useEffect(() => {
    getReleases(1)
    window.scrollTo(0, 0);
    if(!!window.location.hash.replace(/^#/, '')) {
      setListViewActive(false)
    }
  }, [])

  useEffect(() => {
    console.log(savedReleases)
  }, [savedReleases])

  useEffect(() => {
    if(releases) {
      // window.location.hash = releases[activeIndex].slug;
    }
  }, [activeIndex])

  const handleSelectFromList = (i) => {
    let data = JSON.parse(JSON.stringify(savedReleases));
    data.splice(i, 1)
    data.unshift(savedReleases[i]);
    setReleases(data);
    setActiveIndex(0)
    setActiveListIndex(i)
    setListViewActive(false);
  }

  return (
    <>
    { isPage && <Navigation /> }
    {isPage && <h1 className={'mt-16 text-white px-8 font-primary uppercase text-4xl'}>Discography</h1>}
    <div className={' relative mt-[47px] md:mt-0 border border-egyptian-blue hide-scrollbar'}>
    {!listViewActive && <p onClick={() => setListViewActive(true)} className={'cursor-pointer font-secondary z-[999999999999] right-8 md:right-[calc(50%+3rem-4px)] top-[24px] md:top-[80%] text-white absolute text-xl uppercase font-bold font-print'}>see all releases</p>}
{listViewActive &&
        <div className={'absolute h-[calc(100%)] overflow-y-scroll flex bg-black  z-[99] left-0 right-0 flex w-full md:w-3/6'}>
        <div className={'artist-container hide-scrollbar mt-8 grid grid-cols-2 w-full z-[9] mx-8'}>

      { savedReleases && savedReleases.map((artist, i) => {
        return (
          <div onClick={() => handleSelectFromList(i)} className={'cursor-pointer col-span-1'}>

          <img className={`p-2 w-full h-auto ${i == activeListIndex ? 'bg-egyptian-blue shadow-xl brightness-[1.2]' : ''} `} src={artist.thumb}/>

          </div>
        )
      })
      }


        </div>
        </div>
}
      {releases && <CarouselInfo slide={releases[activeIndex]} />}
      <div className={'w-full mx-auto '}>
      {releases && <CarouselSlider carousel={releases} currentSlide={activeIndex} handleCurrentSlide={setActiveIndex}/>}
      </div>
    </div>
    </>
  );
}

export default Discography;
