import React, {useState, useEffect} from 'react';
import './PostArchive.css';
import PostFilter from './post-filter/PostFilter'
import PostPagination from './post-pagination/PostPagination';
import PostList from './post-list/PostList';
import PostSlider from './post-slider/PostSlider';
import PostGrid from './post-grid/PostGrid';
import { getPosts, getCategories } from '../../api';

export const PostArchive = ({category}) => {
  const [currentArchiveState, setCurrentArchiveState] = useState('list')
  const [downloadedCategories, setDownloadedCategories] = useState([])
  const [downloadedPosts, setDownloadedPosts] = useState([])
  const [totalPages, setTotalPages] = useState(null)

  useEffect(() => {
    if(category) {
      getPostPage(1, category)
    } else {
      getPostPage(1)
    }
    getAllCategories()
  }, [])

  const getPostPage = (page) => {
    getPosts(page).then(result => {
      setDownloadedPosts([...result.data])
      setTotalPages(result.headers['x-wp-totalpages'])
    })
  }

  const getAllCategories = () => {
    getCategories().then(result => {
      setDownloadedCategories([...result.filter(r => r.slug !== 'uncategorized')])
    })
  }

  const downloadFilteredPosts = (filter) => {

  }

  const downloadNewPage = (page) => {
    console.log(page)
    setDownloadedPosts([])
    getPostPage(page)
  }


  return (
    <>
    {
      currentArchiveState == 'list' &&
      <div className={'archive-container'}>
        <div className={'archive-inner-container'}>
          <PostFilter categories={downloadedCategories} handleFilter={downloadFilteredPosts}/>
          <PostList posts={downloadedPosts} />
          <PostPagination total={totalPages} handleActivePageChange={downloadNewPage}/>
        </div>
      </div>
    }
    {
      currentArchiveState == 'slider' &&
      <div className={'archive-container'}>
        <PostFilter categories={downloadedCategories} floating={true} />
        <div>
        <PostSlider posts={downloadedPosts} />
        </div>
      </div>
    }
    {
      currentArchiveState == 'grid' &&
      <div className={'archive-container'}>
        <PostFilter categories={downloadedCategories} floating={true} />
        <PostGrid posts={downloadedPosts} noExcerpt header={'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia anim id est laborum.'} itemsPerRow={4} compact={true} />
        <PostPagination total={totalPages} handleActivePageChange={downloadNewPage} floating={true}/>
      </div>
    }
    {
      currentArchiveState == 'grid-narrow' &&
      <div className={'archive-container'}>
        <PostFilter categories={downloadedCategories} />
        <div>
        <PostGrid posts={downloadedPosts} compact={true} />
        </div>
      </div>
    }
    <ul className={'options-menu-archive'}>
    <li onClick={() => setCurrentArchiveState('list')}>List</li>
    <li onClick={() => setCurrentArchiveState('slider')}>Slider</li>
    <li onClick={() => setCurrentArchiveState('grid')}>Grid</li>
    </ul>
    </>
  )
}

export default PostArchive;
