// export {default as About} from './about/About';
// export {default as Contact7} from './contact-7/Contact7';
// export {default as Home} from './home/Home';
export {default as Navigation} from './navigation/Navigation';
export {default as PostArchive} from './post-archive/PostArchive';
export {default as PostSingle} from './post-single/PostSingle'
export {default as PostEmbedSingle} from './post-embed-single/PostEmbedSingle';
export {default as PostInfo} from './post-info/PostInfo';
export {default as Tags} from './tags/Tags';
export {default as Player} from './player/Player';
export {default as CarouselInfo} from './carousel-info/CarouselInfo';
export {default as CarouselSlider} from './carousel-slider/CarouselSlider';

// export {default as Releases} from './releases/Releases'
