import axios from 'axios';
const BASE_URL= process.env.REACT_APP_API_BASE_URL;
//    ╔═══════════════╗
//           BASE
//    ╚═══════════════╝
const invokeWebService = async (url, method, data, formDataHeaders, returnHeader, altURL) => {
  try {
    const token = localStorage.getItem('key')
    const response = await axios({
      method,
      url: altURL ? 'https://admin.blackether.org/' + url : BASE_URL + url,
      data: data || {},
      headers: {
        "Authorization": 'Bearer ' + token,
        "Content-Type": `${formDataHeaders ? 'multipart/form-data' : 'application/json'}`,
      },
      timeout: 90000,
    });
    console.log(response);

    return returnHeader ? {data: response.data, headers: response.headers} : response.data;
  } catch(error) {
    console.log(error.message)
    if (error?.response?.data?.error?.message) {
      throw new Error(error.response.data.error.message);
    }
  }
}

export const invokeApiCall = async (id, payload) => {
  switch (id) {
    case 'demo-form': return sendToContactForm7(payload)
    default: console.log('No action under this id')
  }
}

export const sendToContactForm7 = async(payload) => {
  return invokeWebService(`wp-json/contact-form-7/v1/contact-forms/26/feedback`, 'POST', payload, true)
}

// export const getContactForm7 = async(id) => {
//   return invokeWebService(`wp-json/contact-form-7/v1/contact-forms/26`, 'GET')
// }

export const getCategories = async() => {
  return invokeWebService(`wp-json/wp/v2/categories?_fields=id,name,slug`, 'GET')
}

export const getPosts = async(page, category, tags, altUrl) => {
  console.log(`wp-json/wp/v2/posts?_embed&page=${page}&per_page=100${category?`&categories=${category}`:''}${tags?`&tags=${tags}`:''}`)
  return invokeWebService(`/wp-json/wp/v2/posts?_embed&page=${page}&per_page=100${category?`&categories=${category}`:''}${tags?`&tags=${tags}`:''}`, 'GET', null, false, true, true)
}

export const getPage = async(page) => {
  return invokeWebService(`/wp-json/wp/v2/pages/${page}?_embed`, 'GET');
}

export const getAuthors = async() => {
  return invokeWebService(`/wp-json/wp/v2/users`, 'GET');
}

export const getTags = async() => {
  return invokeWebService(`/wp-json/wp/v2/tags`, 'GET');
}

export const getPostsByTag = async(tag) => {
  return invokeWebService(`/wp-json/wp/v2/posts?_embed&page=1&per_page=100&categories=2&tags=${tag}`, 'GET', null, false, true)
}

// export const getPostsByCategory = async() => {
//   return invokeWebService(`get/the/categories`, 'GET')
// }

export const getPostBySlug = async(slug) => {
  return invokeWebService(`/wp-json/wp/v2/posts?_embed&slug=${slug}`)
}

export const getPostById = async(id) => {
  return invokeWebService(`/wp-json/wp/v2/posts?_embed&include=${id}`)
}
