import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import './Navigation.css';
import logo from '../../assets/katalog-logo.png';
import menu from '../../assets/menu-icon.png';

export const Navigation = ({}) => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  return (
    <>
    <div className={'block md:hidden h-12 brightness-[1] border-b border-b-egyptian-blue blur-panel-max-explosion-grayscale fixed z-[99999] top-0 w-full'}>

    <div onClick={() => setMobileMenuActive(!mobileMenuActive)} className={'block md:hidden relative h-12 w-12 bg-black/50 border-r border-r-egyptian-blue border-b border-b-egyptian-blue flex flex-col gap-1 justify-center items-center'}>
        <div className={'w-8 h-1 border bg-white/50 border-white brightness-[2]'}>
        </div>
        <div className={'w-8 h-1 border bg-white/50 border-white brightness-[2]'}>
        </div>
        <div className={'w-8 h-1 border bg-white/50 border-white brightness-[2]'}>
        </div>
    </div>
    </div>
      <div className={'h-16 brightness-[1] border-t border-t-egyptian-blue  blur-panel-max-explosion fixed bottom-0 w-full'}>

      </div>
    {mobileMenuActive &&
      <div className={'z-[999] slide-fade-in flex justify-center items-center border-y border-y-white fixed top-[48px] rounded-[25%] h-[calc(100%-112px)] w-full top-0 left-0  mobile-menu-container-panel blur-panel'}>
          <div className={'mx-auto w-[300px] py-10 rounded-bl-[100%] rounded-tl-[100%] rounded-br-[100%] rounded-tr-[100%] bg-egyptian-blue/25 flex flex-col items-center text-center uppercase border border-white/25 py-0 font-bold blur-panel-max-explosion'}>
          <div onClick={() => setMobileMenuActive(false)} className={'text-white absolute -top-8 right-2 font-light scale-x-[3] cursor-pointer'}>x</div>
          <Link className={'w-full'} to={'/'}><p className={'text-white font-secondary w-full py-4 hover:bg-egyptian-blue/50 cursor-pointer pb-4 uppercase border-b border-b-white/25'}>Home</p></Link>
            <Link className={'w-full'} to={'/news'}><p className={'text-white font-secondary w-full py-4 hover:bg-egyptian-blue/50 cursor-pointer uppercase border-b border-b-white/25'}>News</p></Link>
            <Link className={'w-full'} to={'/artists'}><p className={'text-white font-secondary w-full py-4 hover:bg-egyptian-blue/50 cursor-pointer uppercase border-b border-b-white/25'}>Artists</p></Link>
            <Link className={'w-full'} to={'/mission'}><p className={'text-white font-secondary w-full py-4 hover:bg-egyptian-blue/50 cursor-pointer pb-4 uppercase border-b border-b-white/25'}>Mission</p></Link>
          </div>
      </div>
    }
    </>
  )
}

export default Navigation;
