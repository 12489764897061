import React, {useRef, useState, useEffect} from 'react';
import videojs from 'video.js'
import "video.js/dist/video-js.css";
import { Navigation } from '../../components/navigation/Navigation';

const Chatango = () => {
  useEffect(() => {
    const chatangoScript = document.createElement('script');
    chatangoScript.type = 'text/javascript';
    chatangoScript.src = 'https://st.chatango.com/js/gz/emb.js';
    chatangoScript.async = true;
    chatangoScript.text = '{"handle":"black3ther000","arch":"js","styles":{"a":"99ff99","b":100,"c":"000000","d":"000000","k":"99ff99","l":"99ff99","m":"99ff99","p":"10","q":"99ff99","r":100,"t":0,"surl":0,"allowpm":0}}';
    chatangoScript.id = 'cid0020000361293470725';
    chatangoScript.style = "width:100%;height:100%"

    document.getElementById('chatango').appendChild(chatangoScript);

    return () => {
      // Cleanup the script when the component is unmounted
      // document.getElementById('chatango').removeChild(chatangoScript);
    };
  }, []);

  return (
    <div id='chatango'></div>
  );
};

export const Videostream = ({isPage}) => {
  const videoNode = useRef(null);
  const [player, setPlayer] = useState(null);

useEffect(() => {
  // HLS (HTTP Live Streaming) source URL
  const hlsSource = 'blackether.org/hls/test_high.m3u8';

  // Configuration options for the player
  const options = {
    fill: true,
    fluid: true,
    autoplay: true,
    controls: true,
    preload: "metadata",
    sources: [
      {
        src: "https://blackether.org/hls/test.m3u8",
        type: "application/x-mpegURL"
      }
    ]
  };

  if (videoNode.current) {
    const _player = videojs(videoNode.current, options);
    setPlayer(_player);
    return () => {
      if (player !== null) {
        player.dispose();
      }
    };
  }
}, []);
  return (
    <>
    { isPage && <Navigation /> }
    {
      <div className={`${isPage ? 'md:mt-0' : 'md:flex md:justify-between'}`}>
      <div data-vjs-player>
      <video ref={videoNode} className="video-js md:w-full min-h-[280px] md:min-h-[420px]" />
      </div>
      {<Chatango />}
      </div>
    }
    </>
  );
}

export default Videostream;
