import React, {useState, useEffect} from 'react';
import './Modal.css';
import bg from '../../assets/about-bg.png';

export const Modal = ({component, sx, classes, title, optionText, handleModalClose, handleModalOptionClick, handleModalPrevNext, handleModalShift, transpositionDisabled, handleTranspositionEnabled}) => {
  const [modalActive, setModalActive] = useState(true);
  const [storedMousePosX, setStoredMousePosX] = useState(null)
  const [storedMousePosY, setStoredMousePosY] = useState(null)
  const [storedModalPosX, setStoredModalPosX] = useState(null)
  const [storedModalPosY, setStoredModalPosY] = useState(null)
  const [modalPosX, setModalPosX] = useState(null)
  const [modalPosY, setModalPosY] = useState(null)
  const [isDragging, setIsDragging] = useState(null);


  const handleModalOpen = () => {
    setModalActive(false);
  }

  const handleDragStart = (e) => {
    handleTranspositionEnabled(true)
    setIsDragging(true);
    handleModalShift();
    e.preventDefault(); // Prevent the default drag behavior
    // setModalOpacity(0);

    const mouseX = e.pageX;
    const mouseY = e.pageY;

    // Calculate the offset from the initial click to the modal position
    const offsetX = mouseX - modalPosX;
    const offsetY = mouseY - modalPosY;

    const handleMouseMove = (e) => {
      const mouseX = e.pageX;
      const mouseY = e.pageY;

      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      // const parentWidth = e.target.parentElement.clientWidth;  // Use clientWidth
      // const parentHeight = e.target.parentElement.clientHeight;  // Use clientHeight
      //
      // // Calculate position of mouse X within element
      // const mouseXWithinParent = storedMousePosX - e.target.parentElement.getBoundingClientRect().left;
      // const mouseYWithinParent = storedMousePosY - e.target.parentElement.getBoundingClientRect().top;

      // Calculate the new modal position
      const newModalPosX = mouseX - offsetX;
      const newModalPosY = mouseY - offsetY;

      // Perform boundary checks if needed
      // ...

      // // Check if the parent element's edges have gone outside the viewport
      // if (mouseX - mouseXWithinParent <= 8) {
      //   setModalPosX(-storedModalPosX + 8);
      // } else if (mouseX + parentWidth - mouseXWithinParent > windowWidth - 8) {
      //   setModalPosX(windowWidth - parentWidth - storedModalPosX - 8);
      // } else {
      //   setModalPosX(newModalPosX);
      // }
      //
      // if (mouseY <= 64) {
      //   setModalPosY(-storedModalPosY + 88);
      // } else if (mouseY + parentHeight - mouseYWithinParent > windowHeight - 8) {
      //   setModalPosY(windowHeight - parentHeight - storedModalPosY - 8);
      // } else {
      //   setModalPosY(newModalPosY);
      // }

      setStoredMousePosX(mouseX);
      setStoredMousePosY(mouseY);

//       const paddingX = 8;
// const paddingYTop = 88;
// const paddingYBottom = 8;

// Perform boundary checks
// setModalPosX(Math.max(paddingX, Math.min(windowWidth - parentWidth - 8, newModalPosX)));
// newModalPosY = Math.max(paddingYTop, Math.min(windowHeight - modalHeight - paddingYBottom, newModalPosY));
      setModalPosX(newModalPosX)
      // setModalPosX(newModalPosX > windowWidth - parentWidth - 118 ? windowWidth - parentWidth - 118 : newModalPosX);
      setModalPosY(newModalPosY);
    };

    const handleMouseUp = () => {
      // Remove event listeners for mousemove and mouseup
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);

      // Restore the modal opacity and perform any additional actions
      // setModalOpacity(1);
      setIsDragging(false)
      handleModalShift();
    };

    // Attach event listeners for mousemove and mouseup
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

    const handleDragEnd = (e) => {

      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const parentWidth = e.target.parentElement.offsetWidth;
      const parentHeight = e.target.parentElement.offsetHeight;
      // Calcalate position of mouse X within element
      const mouseXWithinParent = storedMousePosX - e.target.parentElement.getBoundingClientRect().left;
      const mouseYWithinParent = storedMousePosY - e.target.parentElement.getBoundingClientRect().top;

      // Calculate the modal's new x and y positions
      const mPosX = modalPosX ? modalPosX + mouseX - storedMousePosX : mouseX - storedMousePosX;
      const mPosY = modalPosY ? modalPosY + mouseY - storedMousePosY : mouseY - storedMousePosY;


      // Check if the parent element's edges have gone outside the viewport
      if (mouseX - mouseXWithinParent <= 8) {
        setModalPosX(-storedModalPosX + 8)
      }
      else if (mouseX + parentWidth - mouseXWithinParent > window.innerWidth - 8) {
        setModalPosX(window.innerWidth - parentWidth - storedModalPosX - 8)
      } else {
        setModalPosX(mPosX)
      }

      if (mouseY <= 64) {
        setModalPosY(-storedModalPosY + 88);
      }
      else if (mouseY + parentHeight - mouseYWithinParent > window.innerHeight - 8) {
        setModalPosY(window.innerHeight - parentHeight - storedModalPosY - 8)
      } else {
        setModalPosY(mPosY)
      }

      // Update the mouse position
      setStoredMousePosX(mouseX);
      setStoredMousePosY(mouseY);

      // Handle modal shift or any other actions
      handleModalShift();

      // Return { x: deltaX, y: deltaY };
    };

  return (
    <>
    {
    <div onClick={handleModalShift}
    style={modalPosX ? {...sx,
      'transform': `translate(
        ${modalPosX ? modalPosX : 0}px,
        ${modalPosY ? modalPosY : 0}px
      )`}  : sx
    }
    className={`${classes} ${title == 'Mission' ? 'blur-panel-max-explosion bg-black/50' : ''} ${isDragging ? 'is-dragging' : ''} modal-container border border-egyptian-blue/75 shadow-inner shadow-egyptian-blue/50 ${modalActive ? 'modal-container-active blur-panel-max' : ''}`}>
      {/*<h2 onClick={() => setTagsActive(!tagsActive)}>Tags</h2>*/}
      {modalActive &&
      <>
      <div draggable="true" onDragStart={handleDragStart} onDragEnd={handleDragEnd} className={'modal-container-header blur-panel-max-explosion border border-white/25'}>
      {/*<h3 className={'absolute font-primary text-5xl -left-2 top-0 rotate-90 origin-top-left bg-black font-extralight -translate-y-[1px] -translate-x-1 px-8 uppercase'}>{title}</h3>*/}
        <div className={'modal-header-inner-container'}>
          {handleModalOptionClick && <h6 onClick={handleModalOptionClick} className={'modal-option'}>{optionText ? optionText : ':tags'}</h6>}
          {/*}{handleModalPrevNext && <><h6 onClick={handleModalPrevNext(0)} className={'modal-option'}>:prev</h6><h6 onClick={handleModalPrevNext(1)} className={'modal-option'}>:next</h6></>}*/}
          <p className={'close-modal-btn bg-egyptian-blue text-black border border-egyptian-blue'} onClick={() => handleModalClose()}>x</p>
        </div>
      </div>
      <div className={'modal-content-container'}>
        <div className={'modal-content-inner-container'}>
        {component}
        </div>
      </div>
      </>
      }
    </div>
  }
  </>
)
}

export default Modal;
