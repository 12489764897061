import React, {useState, useEffect} from 'react';
// import './News.css';
import {Navigation} from '../../components/navigation/Navigation';
import square from '../../assets/album6.jpg';
import square2 from '../../assets/album4.jpg';
import square3 from '../../assets/album5.jpg';
import {getPage, getPosts} from '../../api';
import {Link, useNavigate} from 'react-router-dom';

export const News = ({handlePost, isPage}) => {

  const navigate = useNavigate()
  const [news, setNews] = useState(null)
  const [newsBackground, setNewsBackground] = useState(null);

  const CATEGORY_ID = 2;

  const getNews = (page) => {
    getPosts(page, CATEGORY_ID).then(result => {
      console.log(result)
      setNews(result.data.map(post => ({
        id: post.id,
        title: post.title.rendered,
        slug: post.slug,
        excerpt: post.excerpt.rendered,
        content: post.content.rendered,
        banner: post._embedded['wp:featuredmedia'][0].source_url,
        thumbnail: post._embedded['wp:featuredmedia'][0].source_url
      })))
    })
  }

  const handlePagePost = (post) => {
  navigate(`/post/${post.slug}`)
}

  useEffect(() => {
    getNews(1)
  }, [])

  return (
    <>
      { isPage && <Navigation /> }
      {isPage && <h1 className={'mt-16 text-white px-8 font-primary uppercase text-4xl'}>News</h1>}
      <div className={`${isPage ? 'mt-2 px-8 border' : ''} news-content-container hide-scrollbar snap-y smooth-scroll snap-mandatory snap-always ${!isPage ? 'max-h-[368px]' : ''} overflow-y-scroll`}>

      { news &&
        news.map((item) => {
          return (
            <>
            <div onClick={isPage ? () => handlePagePost(item) : () => handlePost(item)} className={'my-8 mb-16 md:my-0 snap-start flex flex-col-reverse md:grid md:grid-cols-5 bg-black/50 md:border-b md:border-egyptian-blue'}>
              <div className="col-span-3 flex justify-center items-center">
              <div>
                <h3 className={'font-primary text-white text-xl px-1 md:ml-3 mt-3'} dangerouslySetInnerHTML={{__html: item.title}}></h3>
                <h4 className={'font-secondary text-md font-light md:py-0 py-1 md:py-4 px-1 md:px-4 md:text-md text-white'} dangerouslySetInnerHTML={{__html: item.excerpt}}></h4>
              </div>
              </div>
              <div className={'col-span-2 flex justify-center items-center overflow-hidden gallery-container'}>
                  <img className={'w-full h-auto bg-white rounded-sm'} src={item.thumbnail}/>
              </div>
            </div>
            </>
          )
        })

      }
      {news && isPage && <div className={'rounded-xl text-center py-2 font-primary border border-white text-2xl text-white mb-32'}><Link to={'/artists'}>See All Artists</Link></div>}



      </div>
    </>
    )
}

export default News;
