import {useState, useEffect} from 'react'
// import placeholder from '../assets/images/43-placeholder.png'
// import DOMPurify from 'dompurify';
import { getPosts, getPostById} from '../../api'
import {CarouselSlider, CarouselInfo} from '../../components';
import {Navigation} from '../../components/navigation/Navigation';

export const Artists = ({isPage}) =>  {
        { isPage && <Navigation /> }

    // const PAGE_ID = -1;
    const CATEGORY_ID = 3;


    const [artists, setArtists] = useState(null)
    const [savedArtists, setSavedArtists] = useState(null);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [activeListIndex, setActiveListIndex] = useState(0)
    const [listViewActive, setListViewActive] = useState(true);
    // const [totalPages, setTotalPages] = useState(null)
    // const [currentPage, setCurrentPage] = useState(1)

    const getArtists = (page) => {
      getPosts(page, CATEGORY_ID, null, true).then(result => {
        let data = result.data.map(post => ({
          id: post.id,
          title: post.title.rendered,
          subtitle: post.acf.post_subtitle,
          excerpt: post.content.excerpt,
          content: post.content.rendered,
          thumb: post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : null,
          slug: post.slug,
          album: post.acf.release_album,
          artist: post.acf.release_artist,
          video_embed: post.acf.release_video,
          bandcamp_embed: post.acf.bandcamp_embed,
          featured_records: post.acf.featured_records,
          instagram: post.acf.artist_instagram,
        }))
        if(!savedArtists) {
          setSavedArtists(data)
        }
        const hash = window.location.hash.replace(/^#/, '');
        if(hash) {
          const selectedIndex = data.map(r => r.slug).indexOf(hash)
          let selectedPost = data[selectedIndex];
          data.splice(data.map(r => r.slug).indexOf(hash), 1)
          data.unshift(selectedPost);
        } else {
        setListViewActive(true)
        }
        setArtists(data)
        setActiveIndex(0)
        console.log(result)
      })
    }

    useEffect(() => {
      getArtists(1)
      window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
      if(artists) {
        // window.location.hash = artists[activeIndex].slug;
      }
    }, [activeIndex])

    const handleSelectFromList = (i) => {
      let data = JSON.parse(JSON.stringify(savedArtists));
      data.splice(i, 1)
      data.unshift(savedArtists[i]);
      setArtists(data);
      setActiveIndex(0)
      setActiveListIndex(i)
      setListViewActive(false);
    }

    return (
      <>
      { isPage && <Navigation /> }

      { isPage && <div className={'hidden md:block text-white font-primary py-4 text-5xl bg-black h-[72px] border-b border-egyptian-blue flex justify-center items-center text-center md:relative'}><h1 >Artists</h1></div>}
      <div className={`relative mt-[47px] ${isPage ? 'h-[calc(100vh-136px)]' : 'h-[calc(100vh-48px)] md:h-auto'}  md:mt-0 border-t-egyptian-blue/25`}>
      {!listViewActive && <p onClick={() => setListViewActive(true)} className={'cursor-pointer z-[999999999999] right-8 md:right-[calc(50%+32px)] top-[16px] md:top-[38px] text-white absolute text-md md:text-xl uppercase font-secondary'}>see all artists</p>}
      { listViewActive &&
        <div className={`absolute h-[calc(100%)] overflow-y-hidden ${isPage ? 'h-[calc(100vh-136px)]' : 'md:h-[calc(100%-0px)]'} flex blur-panel z-[99] left-0 right-0 flex w-full md:w-3/6`}>
        <div className={`artist-container ${isPage ? 'grid-cols-1 md:grid-cols-3' : 'md:grid-cols-2'} grid  w-full ${isPage ? 'h-[calc(100vh-134px)] py-8' : 'h-[660px]'} mt-4 md:border-y py-4  pb-64 border-egyptian-blue overflow-y-scroll overflow-x-hidden z-[9] mx-4`}>

        { savedArtists && savedArtists.map((artist, i) => {
          return (
            <div onClick={() => handleSelectFromList(i)} className={'cursor-pointer col-span-1'}>
            <div className={`${i == activeListIndex ? 'bg-white text-black' : 'bg-black/50 border-white text-white hover:border-egyptian-blue'} blur-panel-max-explosion   flex space-between border rounded-[50px] p-1 mx-2 my-2`}>
            <img className={'w-10 h-10 bg-white rounded-[100%]'} src={artist.thumb}/>
            <p className={'h-10 ml-2 flex text-sm items-center font-primary '}>{artist.title}</p>
            </div>
            </div>
          )
        })
        }


        </div>
      </div>
      }
      {artists && <CarouselInfo slide={artists[activeIndex]} />}
        <div className={'w-full mx-auto'}>
          {artists && <div className={`duration transition-300 ${listViewActive ? 'opacity-0' : 'opacity-100'}`}><CarouselSlider carousel={artists} currentSlide={activeIndex} handleCurrentSlide={setActiveIndex}/></div>}
        </div>
      </div>
      </>
    );
  }

export default Artists;
