import React, {useState, useEffect} from 'react';
import {useHref, Link} from 'react-router-dom'
import { htmlEncode } from '../../helpers/helpers';
import {format} from 'date-fns'
import './PostSingle.css';
import { getPostBySlug } from '../../api';
import blank from '../../assets/blank-square.png';
// import {CircularProgress} from '@material-ui/core';
import { Navigation } from '../../components/navigation/Navigation'

export const PostSingle = ({post, isPage}) => {
  const history = useHref()
  const [pagePost, setPagePost] = useState(null)
  useEffect(() => {
    if(isPage) {
      const slug = history.split('/').pop()
      getPostBySlug(slug).then(result => {
        setPagePost({
            id: result[0].id,
            date: result[0].date,
            monthYear: format(new Date(result[0].date), "yy-MM"),
            title: result[0].title.rendered,
            excerpt: result[0].excerpt.rendered,
            content: result[0].content.rendered,
            slug: result[0].slug,
            audioEmbed: result[0].acf.audio_embed ? result[0].acf.audio_embed : null
            // banner: result[0]._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url,
            // thumbnail: result[0]._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url
        })
      })
    }
  }, [isPage])

  useEffect(() => {
    console.log(pagePost)
  }, [pagePost])
  return (
    <>
    { isPage && <Navigation />}
    { isPage && <Link to={'/'}><p className={'go-back-link'}><u>return</u></p></Link>}
    { (post || pagePost) &&
    <div className={'post-container'}>
      <div className={'post-single-container-header'}>
      <div className={'post-single-inner-container-header'}>
        <div className={'post-single-inner-content-header px-0 py-4 text-center md:text-left md:p-8'}>
        <h1 className={'post-title font-primary text-white text-4xl'} dangerouslySetInnerHTML={{__html: post?.title || pagePost?.title}}></h1>
        {/*<h2 className={'post-subtitle'} dangerouslySetInnerHTML={{__html: post?.excerpt || pagePost?.excerpt}}></h2>*/}
        </div>
      </div>
      </div>
      {post?.audioEmbed && <div style={{marginTop: '1rem'}} dangerouslySetInnerHTML={{__html: post?.audioEmbed || pagePost?.audioEmbed}}>
      </div>}
      <div className={'bg-black/80 rounded-sm shadow shadow-white py-8 post-inner-container text-white font-secondary px-4 md:px-8'} dangerouslySetInnerHTML={{__html: post?.content || pagePost?.content }}>

      </div>
    </div>}
    </>
  )
}

export default PostSingle;
