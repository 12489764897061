import React, {useState, useEffect} from 'react';
import './PostFilter.css';
// import {CircularProgress} from '@material-ui/core';

export const PostFilter = ({floating, categories}) => {
  return (
    <>
    <div className={`filter-container ${floating ? 'floating-filter-container' : ''}`}>
    {
      categories.length !== 0 ?
      <ul>
      { categories.map((category) => {
        return(
          <li>{category.name}</li>
        )
      })
      }
      </ul>
      :
      <div style={{margin: '5px 0'}}>

      </div>
    }

    </div>
    </>
  )
}

export default PostFilter;
