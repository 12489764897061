import React from 'react';
import {useNavigate} from 'react-router-dom';
import './Katalog.css';
import { PostCard } from '../../components/post-card/PostCard';
import { Tags } from '../../components/tags/Tags';
import { Navigation } from '../../components/navigation/Navigation';
import { Modal } from '../../components/modal/Modal';
import {getPosts} from '../../api';
import {sortByDateDesc} from '../../helpers/helpers';
import {format} from 'date-fns';

export const Katalog = ({handlePost, activeTag, isPage, setDateTags, activeDateTag}) => {

  const [downloadedPosts, setDownloadedPosts] = React.useState(null)
  const [pageTagsModalActive, setPageTagsModalActive] = React.useState(null);
        // component={<Tags handleTag={handlePageTagFilter} dateTags={pageDateTags} handleDateTag={setActivePageDateTag}/>

  const navigate = useNavigate();
  const [activePageTag, setActivePageTag] = React.useState(null)
  const [pageDateTags, setPageDateTags] = React.useState(null);
  const [activePageDateTag, setActivePageDateTag] = React.useState(null)

  const handlePagePost = (post) => {
    navigate(`/katalog/${post.slug}`)
    console.log(post);
  }

  const handlePageTagFilter = (tag) => {
    console.log(tag);
    setActivePageTag(tag);
  }

  const handlePageLoadDatesIntoTags = (dates) => {
    console.log(dates);
    setPageDateTags(dates);
  }

  const getKatalogPostPage = (page, tag, filterDate) => {
    const WP_CATEGORY = 2;
    getPosts(page, WP_CATEGORY, tag ? tag.id : null).then(result => {
      console.log(result.data);
      setDownloadedPosts([...result.data,...result.data,...result.data,...result.data,...result.data]
        .map(post => ({
          id: post.id,
          date: post.date,
          monthYear: format(new Date(post.date), "yy-MM"),
          title: post.title.rendered,
          tags: post.tag_names,
          excerpt: post.excerpt.rendered,
          content: post.content.rendered,
          slug: post.slug,
          // banner: post._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url,
          // thumbnail: post._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url
        }))
        .sort(sortByDateDesc)
        .filter(post => filterDate ? (activePageDateTag ? post.monthYear == activePageDateTag : post.monthYear == activeDateTag) : post)
      )
      if(!isPage && !tag) {
        setDateTags(result.data
          .map(post => ({
            label: format(new Date(post.date), 'MMMM yyyy'),
            monthYear: format(new Date(post.date), "yy-MM")
          }))
          .sort(sortByDateDesc)
        )
      } else if (!tag) {
        setPageDateTags(result.data
          .map(post => ({
            label: format(new Date(post.date), 'MMMM yyyy'),
            monthYear: format(new Date(post.date), "yy-MM")
          }))
          .sort(sortByDateDesc)
        )
      }
    })
  }

  React.useEffect(() => {
    getKatalogPostPage(1)
  }, [])

  React.useEffect(() => {
    if(activeTag) {
      getKatalogPostPage(1, activeTag)
    }
  }, [activeTag])

  React.useEffect(() => {
    if(activePageTag) {
      getKatalogPostPage(1, activePageTag)
    }
  }, [activePageTag])

  React.useEffect(() => {
    if(activeDateTag) {
      getKatalogPostPage(1, null, true)
    }
  }, [activeDateTag])

  React.useEffect(() => {
    console.log(activePageDateTag)
    if(activePageDateTag) {
      // conso
      getKatalogPostPage(1, null, true)
    }
  }, [activePageDateTag])

  return (
    <>
    { isPage && <Navigation /> }
    <div className={`katalog-container ${isPage ? 'page-katalog-container' : ''}`}>
    <h3 className={'katalog-page-title'}>{activeTag ? activeTag.name : ':katalog'}</h3>
    { isPage &&
      <div className={'page-katalog-header'}>
        <h6 className={'page-katalog-tags-btn'} onClick={() => setPageTagsModalActive(!pageTagsModalActive)}>:tags</h6>
      </div>
    }
    {/*activeTag && !isPage && <p className="active-tag-container">{activeTag.name}</p>*/}
      {downloadedPosts && downloadedPosts.map((post, i) => {
        return (
          <PostCard card={{
            id: post.id,
            date: post.date,
            title: post.title,
            excerpt: post.excerpt,
            content: post.content,
            banner: post.banner,
            tags: post.tags
          }}
          onCardClick={isPage ? () => handlePagePost(post) : handlePost}

          />
        )
      }, [])}
      {
        !downloadedPosts &&
        <svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>
      }
      {isPage && pageTagsModalActive &&
        <Modal title={':tags'}
        handleModalClose={() => setPageTagsModalActive(false)}
        sx={{zIndex: 999}}
        classes={'scrollableModal secondary-modal tags-modal-container'}
        component={<Tags handleTag={handlePageTagFilter} dateTags={pageDateTags} handleDateTag={setActivePageDateTag}/>}
      />}
    </div>
    </>
  )
}

export default Katalog
