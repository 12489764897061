import React, {useState, useEffect, useRef} from 'react';
import './PostCard.css';
import {Link} from 'react-router-dom';
import blank from '../../assets/blank-square.png';
import { format } from 'date-fns';


export const PostCard = ({card, onCardClick}) => {

  const cardRef = useRef(null);

  const [cardDistance, setCardDistance] = useState(null);
  const [cardOpacity, setCardOpacity] = useState(null);
  const [cardFading, setCardFading] = useState(null);

  useEffect(() => {
  const handleScroll = () => {
    const distanceFromTop = cardRef.current.getBoundingClientRect().top;
    setCardDistance(distanceFromTop)
  };

  window.addEventListener('scroll', handleScroll);

  if(cardDistance === null) {
    setCardDistance(handleScroll)
  }

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

useEffect(() => {
  if (cardDistance > window.innerHeight - 300) {
    setCardOpacity((window.innerHeight - cardDistance - 200) / 100);
  } else {
    setCardOpacity((cardDistance - 60) / 100);
  }
}, [cardDistance])

useEffect(() => {
  if(cardOpacity < 1) {
    setCardFading(true);
  } else {
    setCardFading(false);
  }
}, [cardOpacity])

  return (
    <div onClick={() => onCardClick(card)} className={'post-card-container'}>
      <div ref={cardRef} className={'post-card'}>
        <div className={'post-card-inner'} >
          {card.thumbnail && <div className={'postcard-img-container-desktop'}>
            <img src={card.thumbnail} />
          </div>}
          <div className={'post-card-inner-content'}>
            {/*<h2><span>{card.title}</span></h2>*/}
            <p className={'post-card-excerpt'} dangerouslySetInnerHTML={{__html: card.excerpt}}></p>
            <div className={'tag-flex-container'}>
            {
              card.tags && card.tags.map((t) => {
                return (
                  <p className={'post-card-tag-item'}>{t}</p>
                )
              })
            }
            </div>
            <p className={'post-card-cta'}>View</p>
            {/*<h3 className={'postcard-date'}>{format(new Date(card.date), "do MMMM yyyy")}</h3>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostCard;
