import './App.css';
import React from 'react';

import Home from './pages/home/Home';
import Katalog from './pages/katalog/Katalog';
import About from './pages/about/About';
import Releases from './pages/releases/Releases';
import Mission from './pages/mission/Mission';
import News from './pages/news/News';
import Artists from './pages/artists/Artists';
import Discography from './pages/discography/Discography';
import Videostream from './pages/videostream/Videostream';
import { PostSingle, Navigation, Player } from './components'
import { Modal } from './components/modal/Modal';
import modalContext from './context/ModalContext';
import { htmlEncode } from './helpers/helpers';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';


function App() {
// const location = useLocation();
const [playerActive, setPlayerActive] = React.useState(null)
const [playerAudio, setPlayerAudio] = React.useState(null);

const [showMobileHeaderMenu, setShowMobileHeaderMenu] = React.useState(false);

const handlePlayerAudio = (audio) => {
  setPlayerAudio(audio);
  setPlayerActive(true);
}

  return (
    <>
        <svg style={{display: 'none'}}>
      <filter id='wavy'>
        <feTurbulence x="0" y="0" baseFrequency="0.02"
        numOctaves="5" seed="2">
        <animate attributeName='baseFrequency' dur='120s'
        values='0.00;0.01;0.00' repeatCount='indefinite'></animate>
        </feTurbulence>
        <feDisplacementMap in='SourceGraphic' scale='20'></feDisplacementMap>
        </filter>
    </svg>

      <Router>
        {/*showMobileHeaderMenu && <Navigation />*/}
        <Routes>
          <Route path={'/'} element={<Home />} />
          <Route path={'/about'} element={<About isPage />} />
          <Route path={'/mission'} element={<Mission isPage/>} />
          <Route path={'/news'} element={<News isPage/>} />
          <Route path={'/artists'} element={<Artists isPage/>} />
          <Route path={'/discography'} element={<Discography isPage/>} />
          <Route path={'/livestream'} element={<Videostream isPage/>} />
          <Route path={'/katalog'} element={<Katalog isPage />} />
          <Route path={'/post/:slug'} element={<PostSingle isPage />} />
        </Routes>
        <Player audio={playerAudio} setAudio={setPlayerAudio} active={playerActive} setActive={setPlayerActive}/>
      </Router>
    </>
  );
}

export default App;