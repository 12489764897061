import React, {useState, useEffect} from 'react';
import './PostInfo.css';
export const PostInfo = ({post}) => {
  return (
    <>
      { post &&
      <div className={'post-info-container'}>
          <p dangerouslySetInnerHTML={{__html: post.content}}></p>
      </div>
      }
    </>
  )
}

export default PostInfo;
