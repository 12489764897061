import React, {useState, useEffect} from 'react';
import './Tags.css';
import {getTags, getPostsByTag, dateTags} from '../../api'

export const Tags = ({handleTag, dateTags, handleDateTag}) => {
  const [tagsActive, setTagsActive] = useState(false);
  const [tags, setTags] = useState(null)

  const getTagsList = () => {
    getTags().then(result => {
      setTags(result)
    })
  }

  useEffect(() => {
    getTagsList()
  }, [])

  return (
    <>
    {
    <div className={`tags-container tags-container-active`}>
    <h6 className={'tags-section-header'}>By Tag</h6>
      <div className={'tags-flex-container'}>
      { tags &&
        tags.map((tag) => {
          return (
            <>
              { tag.count !== 0 &&
                <h4 onClick={() => handleTag(tag)}>{tag.name}</h4>
              }
            </>
          )
        })
      }
      </div>
      <h6 className={'tags-section-header'}>By Date</h6>
      <div className={'tags-flex-container'}>
      { dateTags &&
        dateTags.map((dateTag) => {
          return (
            <>
              {
                dateTag.count !== 0 &&
                <h4 onClick={() => handleDateTag(dateTag.monthYear)}>{dateTag.label}</h4>
              }
            </>
          )
        })
      }
      </div>
    </div>
  }
  </>
)
}

export default Tags;
